import React from 'react';
import styles from './MemberCardStatusWarningMessage.module.scss';

const MemberCardStatusWarningMessage = ({ message }) => {
  return (
    <div className={styles.memberCardMessage}>
      <span>{message}</span>
    </div>
  );
};

export default MemberCardStatusWarningMessage;
