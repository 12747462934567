import React,{useReducer} from 'react'
import reducer from './search-context-reducer'

const initialState = {
    keyword: '',
    emptySearch: false,
    location: '',

    orderBy: {
        key: "_score",
        order: 'DESC',
        activeTitle: 'relevance'
    },

    pageSize: 10,
    offset: 0, 

    typeFilters: [],
    authorFilters: [],
    languageFilters: [],
    
    typeBucket: [],
    authorBucket: [],
    languageBucket: [],

    showAllTypeFilter: false,
    showAllAuthorFilter: false,
    showAllLanguageFilter: false,

    libraryCodesFilter: [],
    libraryCodesBucket: [],

    yearFilterEnabled: false,
    fromYearToYear: true,
    matchYear: false,
    fromYear: '',
    toYear: '',
    publishedYear: '',
    publishedYearPrevious: false,
    publishedYearAfter: false
}

export const SearchContextState = React.createContext(initialState)
export const SearchContextDispatch = React.createContext({})

export default props => {
    const [state, dispatch] = useReducer(reducer,initialState)

    return (
        <SearchContextState.Provider value={state}>
            <SearchContextDispatch.Provider value={dispatch}>
                {props.children}
            </SearchContextDispatch.Provider>
        </SearchContextState.Provider>
    )
}