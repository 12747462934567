import React, { useState } from 'react';
import { createContext, useEffect } from 'react';
import { accessTokenVar } from '../apollo/localState/accessTokenVar';
import { useQuery, useReactiveVar } from '@apollo/client';
import { FETCH_SYSTEM_STATUS } from '../apollo/queries/systemStatus';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Maintenance from '../components/Maintenance/Maintenance';

const defaultValues = {
  maintenance: false,
  headerMessage: '',
};

export const SystemStatusContext = createContext(defaultValues);

const SystemStatusProvider = ({ ...props }) => {
  const { accessToken } = useReactiveVar(accessTokenVar);

  const isSessionStorageAvailable =
    typeof window !== 'undefined' && window.sessionStorage;

  const maintenanceSessionValue = isSessionStorageAvailable
    ? sessionStorage.getItem('maintenance')
    : '';
  const maintenanceInitialState = maintenanceSessionValue === 'true';
  const [maintenance, setMaintenance] = useState(maintenanceInitialState);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbarHistorySessionValue = isSessionStorageAvailable
    ? sessionStorage.getItem('snackbarHistory')
    : '';
  const snackbarHistoryInitialState = snackbarHistorySessionValue
    ? JSON.parse(snackbarHistorySessionValue)
    : [];
  const [snackbarHistory, setSnackbarHistory] = useState(
    snackbarHistoryInitialState
  );

  const { data } = useQuery(FETCH_SYSTEM_STATUS, {
    pollInterval: 60000,
  });

  const { mode, pinnedHeaderMessage: headerMessage, notistackMessages } =
    data?.systemStatus ?? {};

  /** Updates maintenance mode. */
  useEffect(() => {
    if (mode) {
      const newMaintenance =
        mode === 'Maintenance_ReadOnly' || mode === 'Maintenance_Closed';
      setMaintenance(newMaintenance);
      if (isSessionStorageAvailable) {
        sessionStorage.setItem('maintenance', newMaintenance.toString());
      }
    }
  }, [mode, isSessionStorageAvailable]);

  /** Sends notistack messages. */
  useEffect(() => {
    if (notistackMessages) {
      notistackMessages.forEach(notistackMessage => {
        const {
          behaviour,
          id,
          kind,
          message,
          persistence,
          trigger,
        } = notistackMessage;

        const persist = behaviour === 'Fixed_Closeable';
        const isTriggered =
          trigger === 'Active_Session_Polling_Only' && accessToken;
        const isAllowed =
          !(persistence === 'Once') || !snackbarHistory.includes(id);

        if (isTriggered && isAllowed) {
          enqueueSnackbar(message, {
            key: id,
            variant: kind,
            persist,
            action: () => (
              <IconButton onClick={() => closeSnackbar(id)}>
                <CloseIcon />
              </IconButton>
            ),
          });

          const newSnackbarHistory = [...snackbarHistory, id];
          setSnackbarHistory(newSnackbarHistory);
          if (isSessionStorageAvailable) {
            sessionStorage.setItem(
              'snackbarHistory',
              JSON.stringify(newSnackbarHistory)
            );
          }
        }
      });
    }
  }, [
    notistackMessages,
    accessToken,
    enqueueSnackbar,
    closeSnackbar,
    snackbarHistory,
    isSessionStorageAvailable,
  ]);

  /** Logs out the user. */
  useEffect(() => {
    if (maintenance && accessToken) {
      accessTokenVar({ accessToken: '' });
      if (isSessionStorageAvailable) {
        sessionStorage.removeItem('accessToken');
      }
    }
  }, [maintenance, accessToken, isSessionStorageAvailable]);

  return (
    <SystemStatusContext.Provider
      value={{
        maintenance,
        headerMessage,
      }}
      {...props}
    >
      {mode === 'Maintenance_Closed' ? <Maintenance /> : props.children}
    </SystemStatusContext.Provider>
  );
};

export default SystemStatusProvider;
