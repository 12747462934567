import React, { useContext, useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Snackbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuSharp';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import styles from './mainmenu.module.scss';
import ErrorSnackbar from '../ErrorSnackbar/ErrorSnackbar';
import { Link, navigate } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { accessTokenVar } from '../../apollo/localState/accessTokenVar';
import { useReactiveVar } from '@apollo/client';
import { userInfoVar } from '../../apollo/localState/userInfoVar';
import { SystemStatusContext } from '../../providers/SystemStatusProvider';
import { controlledFeatures } from '../../utils/featureControl';

const NavigationBar = () => {
  const menuItemsData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { staticPage: { eq: true } } }) {
        nodes {
          frontmatter {
            title
            # isActive
          }
          fields {
            slug
          }
        }
      }
    }
  `);

  const [accountProprieties, setAccountProprietes] = useState({
    left: 0,
    top: 0,
    show: false,
  });

  // const { accessToken } = useAuth();
  const { accessToken } = useReactiveVar(accessTokenVar);
  const { isMemberCardActive } = useReactiveVar(userInfoVar);
  const { maintenance } = useContext(SystemStatusContext);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  // const mouseLeaveAccountIconHandler = () => {
  //   setAccountProprietes({
  //     ...accountProprieties,
  //     show: false,
  //   });
  // };

  // const logOffHandler = () => {
  //   setAccessToken('');
  //   dispatch({
  //     type: actionTypes.REMOVE_ACCESS_TOKEN,
  //   });
  // };

  const openSnackbarModalHandler = () => {
    setOpenSnackbar(true);
  };

  const closeErrorSnackbarHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  let menuItems = [];

  if (
    controlledFeatures.submissionReport &&
    !controlledFeatures.homepagePath.includes('kotelespeldanyok')
  ) {
    menuItems.push(
      <li className={styles.listItem} key="kotelespeldanyok">
        <Link
          to="/kotelespeldanyok"
          className={styles.listItemLink}
          activeClassName={styles.listItemLinkActive}
        >
          Kötelespéldány-szolgáltatás
        </Link>
      </li>
    );
  }

  if (controlledFeatures.submissionReport) {
    menuItems.push(
      <li className={styles.listItem} key="kotelespeldany-szolgaltatasrol">
        <Link
          to="/kotelespeldany-szolgaltatasrol"
          className={styles.listItemLink}
          activeClassName={styles.listItemLinkActive}
        >
          A szolgáltatásról
        </Link>
      </li>
    );
  }

  if (controlledFeatures.login) {
    if (!accessToken) {
      if (!maintenance) {
        menuItems.push(
          <li className={styles.listItem} key="bejelentkezes">
            <Link
              to="/bejelentkezes"
              className={styles.listItemLink}
              activeClassName={styles.listItemLinkActive}
              state={{ navigateBack: true }}
            >
              Bejelentkezés
            </Link>
          </li>
        );
      }
    } else {
      menuItems.push(
        <li className={styles.listItem} key="profil">
          <Link
            to="/profil"
            className={styles.listItemLink}
            activeClassName={styles.listItemLinkActive}
          >
            {!isMemberCardActive && (
              <ReportProblemOutlinedIcon
                className={styles.listItemLinkIcon}
                fontSize="small"
              />
            )}
            <span>Profil</span>
          </Link>
        </li>
      );
      menuItems.push(
        <li className={styles.listItem} key="kijelentkezes">
          <div
            // to="/bejelentkezes"
            onClick={() => {
              accessTokenVar({ accessToken: '' });
              navigate('/');
              sessionStorage.removeItem('accessToken');
            }}
            className={styles.listItemLink}
          >
            Kijelentkezés
          </div>
        </li>
      );
    }
  }

  const generatedMenuItems = menuItemsData.allMarkdownRemark.nodes.map((node) => {
    let returnValue = (
      <li className={styles.listItem} key={node.frontmatter.title}>
        <Link
          to={`/${node.fields.slug}`}
          className={styles.listItemLink}
          activeClassName={styles.listItemLinkActive}
        >
          {node.frontmatter.title}
        </Link>
      </li>
    );

    if (!node.frontmatter.isActive) {
      returnValue = (
        <li className={styles.listItem} key={node.frontmatter.title}>
          <div
            className={styles.listItemLink}
            style={{ cursor: 'pointer' }}
            onClick={openSnackbarModalHandler}
          >
            {node.frontmatter.title}
          </div>
        </li>
      );
    }

    return returnValue;
  });

  menuItems.push(generatedMenuItems);

  return (
    <React.Fragment>
      <nav className={styles.navigationBar}>
        <ul className={styles.list}>{menuItems}</ul>
        <div className={styles.mobileMenuList}>
          <ExpansionPanel className={styles.mobileMenuListContainer} square={false}>
            <ExpansionPanelSummary
              expandIcon={<MenuIcon />}
              className={styles.mobileMenuListSum}
            ></ExpansionPanelSummary>
            <ExpansionPanelDetails className={styles.mobileMenuListDet}>
              <ul className={styles.mobileMenuList}>{menuItems}</ul>
              {/* <SearchBar /> */}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </nav>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={closeErrorSnackbarHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorSnackbarHandler}
          variant="warning"
          message={'Hamarosan'}
        />
      </Snackbar>
    </React.Fragment>
  );
};

export default NavigationBar;
