import {
  ApolloClient,
  gql,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';
import { accessTokenVar } from './localState/accessTokenVar';

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.GATSBY_BACKEND_API_URL,
});

const getClient = accessToken => {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    fetch,
    resolvers: {
      BibliographicRecord: {
        async hasActiveRequest(parent, _, { client }) {
          if (!accessToken) return false;

          try {
            const res = await client.query({
              query: gql`
                query($first: Int, $skip: Int) {
                  myActiveRequests(first: $first, skip: $skip) {
                    aggregate {
                      count
                    }
                    nodes {
                      id
                      requestDate
                      expiryDate
                      bibliographicRecord {
                        guid
                        title
                        author
                      }
                    }
                  }
                }
              `,
              variables: {
                first: 100,
                skip: 0,
              },
            });

            return res.data.myActiveRequests.nodes.some(
              ({ bibliographicRecord }) =>
                bibliographicRecord.guid === parent.guid
            );
          } catch (err) {
            return false;
          }
        },
      },
    },
    request: operation => {
      const token = accessTokenVar();
      operation.setContext({
        headers: {
          authorization: `Bearer ${token?.accessToken ?? 'fakeAdmin'}`,
        },
      });
    },
  });

  return client;
};

export default getClient;
