import { gql } from '@apollo/client';

export const GET_SEARCH_FIELDS = gql`
  query searchFields_v2($searchString: String) {
    searchFields_v2(searchString: $searchString)
  }
`;

export const SEARCH_RECORDS = gql`
  query complexSearchBibliographicsRecords_v2(
    $filters: JSON
    $limit: Int
    $offset: Int
    $searchQuery: JSON
    $sortOrder: complexSearchBibliographicsRecords_v2_SortOrder
    $sortType: String
  ) {
    complexSearchBibliographicsRecords_v2(
      filters: $filters
      limit: $limit
      offset: $offset
      searchQuery: $searchQuery
      sortOrder: $sortOrder
      sortType: $sortType
    ) {
      count
      recordCount
      aggregations
      records {
        id
        # record
        type
        profileId
        # _physicalManifestations_count
      }
    }
  }
`;

export const SEARCH_SIMPLE_RECORDS = gql`
  query simpleSearchBibliographicsRecords_v2(
    $filters: JSON
    $limit: Int
    $offset: Int
    $sortOrder: SimpleSearchBibliographicsRecords_v2_SortOrder
    $sortType: String
    $searchValue: String
    $searchType: String
  ) {
    simpleSearchBibliographicsRecords_v2(
      filters: $filters
      limit: $limit
      offset: $offset
      sortOrder: $sortOrder
      sortType: $sortType
      searchValue: $searchValue
      searchType: $searchType
    ) {
      count
      recordCount
      aggregations
      records {
        id
        record
        type
        profileId
        # _physicalManifestations_count
      }
    }
  }
`;

export const GET_INDEX_ENTRIES = gql`
  query simpleSearchIndexEntries(
    $first: Int
    $where: IndexEntrySimpleSearchInput
  ) {
    simpleSearchIndexEntries(where: $where, first: $first) {
      guid
      generatedDisplayName
      properties {
        nodes {
          guid
          predicate
          object
        }
      }
    }
  }
`;

export const GET_PROFILES = gql`
  query getProfilesByIds($first: Int, $where: MarcProfilesWhereInput!) {
    getProfilesByIds(where: $where, first: $first) {
      aggregate
      nodes
    }
  }
`;

export const ME_QUERY = gql`
  query myActiveTransactions {
    me {
      guid
      myActiveLoans {
        id
        item {
          guid
          physicalManifestationOf {
            guid
            title
          }
        }
      }
      myActiveReservations {
        guid
        createdAt
        status
        expirationDate
        expectedDeliveryDate
        targetItem {
          guid
        }
        targetLoanZone {
          publicName
          publicContactPhone
          publicContactEmail
        }
      }
      myActiveRequests {
        id
        status
        requestDate
        expiryDate
        bibliographicRecord {
          guid
        }
        item {
          guid
          physicalManifestationOf {
            guid
          }
        }
      }
    }
  }
`;

export const FETCH_MY_TRANSACTIONS = gql`
  query fetchMyTransactions($take: Int, $skip: Int) {
    me {
      guid
      myActiveLoans(take: $take, skip: $skip) {
        id
        loanDate
        expiryDate
        returnDate
        returned
        item {
          guid
          physicalManifestationOf {
            guid
            title
          }
        }
      }
      myActiveReservations(take: $take, skip: $skip) {
        guid
        createdAt
        status
        expirationDate
        expectedDeliveryDate
        targetItem {
          guid
          physicalManifestationOf {
            guid
            title
          }
        }
        targetLoanZone {
          publicName
          publicContactPhone
          publicContactEmail
        }
      }
      myActiveRequests(take: $take, skip: $skip) {
        id
        status
        requestDate
        expiryDate
        bibliographicRecord {
          guid
          title
        }
        item {
          guid
          physicalManifestationOf {
            guid
            title
          }
        }
      }
    }
  }
`;

export const FETCH_MY_TRANSACTIONS_COUNT = gql`
  query fetchMyTransactionsCount {
    me {
      myActiveLoans_count
      myActiveReservations_count
      myActiveRequests_count
    }
  }
`;

const ITEMS_FIELDS = gql`
  fragment ItemsFields on BibliographicRecord {
    physicalManifestations {
      aggregate {
        count
      }
    }
  }
`;

export const getResolvedRecordsQuery = ({ fetchItems }) => {
  const query = gql`
  ${fetchItems ? ITEMS_FIELDS : ''}
  query fetchResolvedRecords(
    $where: searchBibliographicRecordInput
    $first: Int
    $skip: Int
  ) {
    searchBibliographicRecord(where: $where, first: $first, skip: $skip) {
      nodes {
        guid
        rawMarcRecord
        ${fetchItems ? '...ItemsFields' : ''}

        publicFiles {
          isProtected
          classification
          displayName
          publicUrl
          protectedUrl
        }
      }
    }
  }
`;

  return query;
};
