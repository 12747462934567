import * as actionTypes from '../actionTypes'

import {
    onSetKeywordForBasicSearch,
    onEmptySearch
} from './reducer-functions/keyword-reducer-functions'

import {
    onAddTypeFilter,
    onChangeFilterVisual,
    onFilterForExactYear,
    onFilterForMatchYear,
    onFilterForYearToYear,
    onAddLibraryCodeFilter,
    onAddLibraryElseFilter,
    onSetBuckets
} from './reducer-functions/filter-reducer-functions'

import {
    setAbcSort
} from './reducer-functions/order-reducer-functions'

const reducer = (state,action) => {
    switch(action.type){
        case actionTypes.ON_SET_KEYWORD_FOR_BASIC_SEARCH:                   return onSetKeywordForBasicSearch(state,action)
        case actionTypes.ON_SET_KEYWORD_FROM_URL:                           return {...state, keyword: action.keyword, emptySearch: false}
        case actionTypes.ON_EMPTY_SEARCH:                                   return onEmptySearch(state,action)
        
        case actionTypes.ON_SEARCHABLE_LOCATION_CHANGE:                     return {...state, location: action.location}
        
        case actionTypes.ON_ADD_TYPE_FILTER:                                return onAddTypeFilter(state,action)
        case actionTypes.ON_CHANGE_FILTER_VISUAL:                           return onChangeFilterVisual(state,action)

        case actionTypes.ON_FILTER_FOR_YEAR_TO_YEAR:                        return onFilterForYearToYear(state,action)
        case actionTypes.ON_FILTER_FOR_EXACT_YEAR:                          return onFilterForExactYear(state,action)
        case actionTypes.ON_FILTER_FOR_MATCH_YEAR:                          return onFilterForMatchYear(state,action)
        case actionTypes.ON_SET_BUCKETS:                                    return onSetBuckets(state,action)  


        case actionTypes.SET_ABC_SORT:                                      return setAbcSort(state,action)

        case actionTypes.SET_PAGE_SIZE:                                     return {...state, pageSize: action.size}          
        case actionTypes.SET_OFFSET:                                        return {...state, offset: action.offset}    

        case actionTypes.ADD_LIBRARY_CODE_FILTER:                           return onAddLibraryCodeFilter(state,action)    
        case actionTypes.ADD_LIBRARY_ELSE_FILTER:                           return onAddLibraryElseFilter(state,action)

        default: return state
    }
}

export default reducer