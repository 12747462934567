import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styles from './footer.module.scss';
import MediaKit from '../assets/MediaKit';

const Footer = () => {
  return (
    <footer className={styles.footerWrapper}>
      <Container maxWidth="lg" className={styles.footerWrapperInner}>
        <Grid item md={12}>
          <p>© OSZK 2024–</p>
        </Grid>
      </Container>
      <Container maxWidth="lg" className={styles.footerWrapperInner}>
        <Grid item>
          <a href="https://www.oszk.hu/">
            <div></div>
          </a>
        </Grid>
        {MediaKit.footer?.displayLibMateLogo && (
          <Grid
            // container
            // component="div"
            // direction="column"
            // justifyContent="center"
            className={styles.footerWrapperInnerGrid}
          >
            <span className={styles.footerWrapperText}>
              <a href="/">
                <div></div>
              </a>
              {/* LibMate <br/><span>– a könyvtárs</span> */}
            </span>
          </Grid>
        )}
      </Container>
      <Container maxWidth="lg" className={styles.footerWrapperInner}>
        <Grid item md={12}>
          <p>Cím: {MediaKit.contacts?.physicalAddress}</p>
          <p>Telefon: {MediaKit.contacts?.phoneNumbers?.join(', ')}</p>
          <p>E-mail: {MediaKit.contacts?.emailAddress}</p>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
