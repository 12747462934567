import React from 'react';
import { MyTransactionContext } from "../context/myTransactionContext"
import { useFetchAllTransaction } from "../hooks/useFetchAllTransaction";

export const MyTransactionProvider = ({ children }) => {
  const data = useFetchAllTransaction();

  return <MyTransactionContext.Provider value={data}>
    {children}
  </MyTransactionContext.Provider>
}