import React from 'react';

import {Grid} from '@material-ui/core';

import Header from '../components/header';
import Footer from '../components/footer';

import '../styles/basic_style.scss'

const BasicLayout = (props) => {
  return (
      <Grid
          component="div"
          container
          spacing={0}
          direction="column"
          justifyContent="space-between"
          className={"siteBasicContainer"}
      >
        <Header/>
          {props.children}
        <Footer/>
      </Grid>
  )
}

export default BasicLayout