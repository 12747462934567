/**
 * @type {MediaKit}
 */
const mediaKit = {
  siteMetadata: {
    title: 'KSH Könyvtár',
  },
  header: {
    logoImageSrc: '/ksh/kshk_logo2x.png',
    logoLinkUrl: 'http://www.kshkonyvtar.hu/',
  },
  body: {
    backgroundImageSrc: '/ksh/bt.jpg',
  },
  footer: {
    displayLibMateLogo: true,
  },
  contacts: {
    physicalAddress: '1024 Budapest, Keleti Károly utca 5.',
    phoneNumbers: ['+36 (1) 345-6339'],
    emailAddress: 'kolcsonzes@kshkonyvtar.hu',
  },
};

module.exports = mediaKit;
