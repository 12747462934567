/**
 * @type {MediaKit}
 */
const mediaKit = {
  siteMetadata: {
    title: 'OSZK Köteles Iroda',
  },
  header: {
    logoImageSrc: '/ksh/LibMate_logo.png',
    logoLinkUrl: '/',
  },
  body: {
    backgroundImageSrc: '/isxn/koteles_szolg_kep.jpg',
  },
  footer: {
    displayLibMateLogo: false,
  },
  contacts: {
    physicalAddress: '1014 Budapest, Szent György tér 4–5–6.',
    phoneNumbers: ['+36 1 224-3840'],
    emailAddress: 'koteles@oszk.hu',
  },
};

module.exports = mediaKit;
