import { makeVar } from '@apollo/client';

const defaultValue = {
  accessToken: '',
};

export const accessTokenVar = makeVar(defaultValue);

if (typeof window !== 'undefined') {
  const { accessToken } = accessTokenVar();

  if (!accessToken || accessToken === '') {
    accessTokenVar({
      accessToken: window?.sessionStorage?.getItem('accessToken'),
    });
  }
}
