export const onSetKeywordForBasicSearch = (state, action) => {
  return {
    ...state,
    keyword: action.keyword,
    emptySearch: false,
    location: "",

    orderBy: {
      key: "_score",
      order: "DESC",
      activeTitle: "relevance",
    },

    pageSize: 10,
    offset: 0,

    typeFilters: [],
    authorFilters: [],
    languageFilters: [],

    typeBucket: [],
    authorBucket: [],
    languageBucket: [],

    showAllTypeFilter: false,
    showAllAuthorFilter: false,
    showAllLanguageFilter: false,

    libraryCodesFilter: [],
    libraryCodesBucket: [],

    yearFilterEnabled: false,
    fromYearToYear: true,
    matchYear: false,
    fromYear: "",
    toYear: "",
    publishedYear: "",
    publishedYearPrevious: false,
    publishedYearAfter: false,
  }
}

export const onEmptySearch = (state, action) => {
  return {
    ...state,
    keyword: "",
    emptySearch: true,
    location: "",

    orderBy: {
      key: "_score",
      order: "DESC",
      activeTitle: "relevance",
    },

    pageSize: 10,
    offset: 0,

    typeFilters: [],
    authorFilters: [],
    languageFilters: [],

    typeBucket: [],
    authorBucket: [],
    languageBucket: [],

    showAllTypeFilter: false,
    showAllAuthorFilter: false,
    showAllLanguageFilter: false,

    libraryCodesFilter: [],
    libraryCodesBucket: [],

    yearFilterEnabled: false,
    fromYearToYear: true,
    matchYear: false,
    fromYear: "",
    toYear: "",
    publishedYear: "",
    publishedYearPrevious: false,
    publishedYearAfter: false,
  }
}
