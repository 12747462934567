import React, { createContext, useState } from 'react';

const AuthContext = createContext({
  accessToken: null,
  setAccessToken: () => {},
});

function AuthProvider(props) {
  const [accessToken, setAccessToken] = useState(null);

  //   const [accessToken, setAccessToken] = useState(
  //     'eyJhbGciOiJIUzI1NiJ9.eyJndWlkIjoiY2tnYnhoNHBqMXJjYTA3NjJvb3JodmdjbiIsInVzZXJuYW1lIjoiZ2lwc3pqIiwiZmlyc3ROYW1lIjoiSmFrYWIiLCJsYXN0TmFtZSI6IkdpcHN6IiwiaWF0IjoxNjA0MTU5MjM0LCJleHAiOjE2MDQyNDU2MzR9.fRdIdCzC2GWllHHuHCzJas8kiKjGZpcsyjP_bJlCQKM'
  //   );

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken }} {...props}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
