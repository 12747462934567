export const onSetBuckets = (state, action) => {
  const { authors, types, languages, libraries } = action

  return {
    ...state,
    typeBucket: types,
    authorBucket: authors,
    languageBucket: languages,
    libraryCodesBucket: libraries,
  }
}

export const onAddTypeFilter = (state, action) => {
  const { filterBool, filterValue, filterCategory, elseItems } = action
  switch (filterCategory) {
    case "type": {
      if (filterBool) {
        if (elseItems) {
          const updTypeFilters = state.typeFilters
          elseItems.map(item => {
            updTypeFilters.push(item.key)
          })
          return {
            ...state,
            typeFilters: updTypeFilters,
          }
        }

        return {
          ...state,
          typeFilters: [...state.typeFilters, filterValue],
        }
      } else {
        if (elseItems) {
          const currentTypeState = state.typeFilters
          elseItems.map(item => {
            const findRemoveableIndex = currentTypeState.findIndex(
              typeItem => typeItem === item.key
            )
            currentTypeState.splice(findRemoveableIndex, 1)
          })

          return {
            ...state,
            typeFilters: currentTypeState,
          }
        }

        const updatedTypeFilter = state.typeFilters.filter(
          type => type !== filterValue
        )

        return {
          ...state,
          typeFilters: updatedTypeFilter,
        }
      }
    }
    case "author": {
      if (filterBool) {
        return {
          ...state,
          authorFilters: [...state.authorFilters, filterValue],
        }
      } else {
        const updatedAuthorFilter = state.authorFilters.filter(
          type => type !== filterValue
        )

        return {
          ...state,
          authorFilters: updatedAuthorFilter,
        }
      }
    }
    case "language": {
      if (filterBool) {
        if (elseItems) {
          const updLanguageFilters = state.languageFilters
          elseItems.map(item => {
            return updLanguageFilters.push(item.key)
          })
          return {
            ...state,
            languageFilters: updLanguageFilters,
          }
        }

        return {
          ...state,
          languageFilters: [...state.languageFilters, filterValue],
        }
      } else {
        if (elseItems) {
          const currentLanguageState = state.languageFilters
          elseItems.map(item => {
            const findRemoveableIndex = currentLanguageState.findIndex(
              langCode => langCode === item.key
            )
            currentLanguageState.splice(findRemoveableIndex, 1)
          })

          return {
            ...state,
            languageFilters: currentLanguageState,
          }
        }

        const updatedLanguageFilter = state.languageFilters.filter(
          type => type !== filterValue
        )

        return {
          ...state,
          languageFilters: updatedLanguageFilter,
        }
      }
    }
    default:
      return state
  }
}

export const onChangeFilterVisual = (state, action) => {
  switch (action.visual) {
    case "type":
      return { ...state, showAllTypeFilter: !state.showAllTypeFilter }
    case "author":
      return { ...state, showAllAuthorFilter: !state.showAllAuthorFilter }
    case "language":
      return { ...state, showAllLanguageFilter: !state.showAllLanguageFilter }
    default:
      return state
  }
}

export const onFilterForYearToYear = (state, action) => {
  if (
    action.fromYear === "" ||
    action.toYear === "" ||
    !action.fromYear ||
    !action.toYear
  ) {
    return {
      ...state,
      yearFilterEnabled: false,
      fromYearToYear: true,
      matchYear: false,
      fromYear: action.fromYear,
      toYear: action.toYear,
      publishedYear: '',
      publishedYearPrevious: false,
      publishedYearAfter: false
    }
  }

  return {
    ...state,
    yearFilterEnabled: true,
    fromYearToYear: true,
    matchYear: false,
    fromYear: action.fromYear,
    toYear: action.toYear,
  }
}

export const onFilterForExactYear = (state, action) => {
  if (action.publishedYear === "" || !action.publishedYear) {
    return {
      ...state,
      yearFilterEnabled: false,
      fromYearToYear: false,
      matchYear: false,
      publishedYear: action.publishedYear,
      publishedYearPrevious: action.previous,
      publishedYearAfter: action.after,
    }
  }

  return {
    ...state,
    yearFilterEnabled: true,
    fromYearToYear: false,
    matchYear: false,
    publishedYear: action.publishedYear,
    publishedYearPrevious: action.previous,
    publishedYearAfter: action.after,
  }
}

export const onFilterForMatchYear = (state, action) => {
  if (action.publishedYear === "" || !action.publishedYear) {
    return {
      ...state,
      yearFilterEnabled: false,
      matchYear: true,
      publishedYear: action.publishedYear,
    }
  }

  return {
    ...state,
    yearFilterEnabled: true,
    matchYear: true,
    publishedYear: action.publishedYear,
  }
}

export const onAddLibraryCodeFilter = (state, action) => {
  const { filterBool, codes } = action
  const codesDocCountRemoveArray = codes.map(code => code.code)

  if (filterBool) {
    const updLibraryCodesFilter = state.libraryCodesFilter.concat(
      codesDocCountRemoveArray
    )

    return {
      ...state,
      libraryCodesFilter: updLibraryCodesFilter,
    }
  }

  const updLibraryCodesFilter = state.libraryCodesFilter.filter(filterCode => {
    return codesDocCountRemoveArray.find(findCode => findCode === filterCode)
      ? false
      : true
  })

  return {
    ...state,
    libraryCodesFilter: updLibraryCodesFilter,
  }
}

export const onAddLibraryElseFilter = (state, action) => {
  const { filterBool, codes } = action
  const codesDocCountRemoveArray = codes.map(code => code.code)

  if (filterBool) {
    const updLibraryCodesFilter = state.libraryCodesFilter.concat(
      codesDocCountRemoveArray
    )

    return {
      ...state,
      libraryCodesFilter: updLibraryCodesFilter,
    }
  }

  const updLibraryCodesFilter = state.libraryCodesFilter.filter(filterCode => {
    return codesDocCountRemoveArray.find(findCode => findCode === filterCode)
      ? false
      : true
  })

  return {
    ...state,
    libraryCodesFilter: updLibraryCodesFilter,
  }
}
