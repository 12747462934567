import { gql } from '@apollo/client';

export const FETCH_SYSTEM_STATUS = gql`
  query fetchSystemStatus {
    systemStatus {
      mode
      pinnedHeaderMessage
      notistackMessages
    }
  }
`;
