import React, { useEffect } from 'react';

import { ApolloProvider, gql, useReactiveVar } from '@apollo/client';
import { SnackbarProvider, useSnackbar } from 'notistack';

import huLocale from 'date-fns/locale/hu';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import BasicSearchProvider from '../context/search-context/search-context';
import { AuthProvider } from '../context/auth-context/auth-context';
import getClient from './client';
import { Helmet } from 'react-helmet';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { accessTokenVar } from '../apollo/localState/accessTokenVar';
import { userInfoVar } from '../apollo/localState/userInfoVar';
import { MyTransactionProvider } from '../providers/MyTransactionProvider';
import SystemStatusProvider from '../providers/SystemStatusProvider';

export const wrapRootElement = ({ element }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#e8b96b', // $color-gold from scss
      },
    },
  });

  return (
    <AuthProvider>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
      </Helmet>

      <MuiThemeProvider theme={theme}>
        <BasicSearchProvider>
          <SnackbarProvider maxSnack={3}>
            <ApolloWrapper>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={huLocale}>
                <SystemStatusProvider>
                  <MyTransactionProvider>{element}</MyTransactionProvider>
                </SystemStatusProvider>
              </MuiPickersUtilsProvider>
            </ApolloWrapper>
          </SnackbarProvider>
        </BasicSearchProvider>
      </MuiThemeProvider>
    </AuthProvider>
  );
};

const ApolloWrapper = props => {
  const { accessToken } = useReactiveVar(accessTokenVar);
  const { enqueueSnackbar } = useSnackbar();

  const client = getClient(accessToken);

  useEffect(() => {
    if (!accessToken || accessToken === '') return;

    (async () => {
      const { data: userInfo } = await client.query({
        query: gql`
          query getUserInfo {
            me {
              guid
              memberCardStatusHelper
            }
          }
        `,
      });

      const isMemberCardActive =
        userInfo?.me?.memberCardStatusHelper === 'Active';

      userInfoVar({
        isMemberCardActive,
        status: userInfo?.me?.memberCardStatusHelper,
      });

      if (!isMemberCardActive && userInfo?.me?.memberCardStatusHelper) {
        const statusMessage = {
          Inactive: 'inaktív',
          Expired: 'lejárt',
          Disabled: 'tiltva',
        };

        enqueueSnackbar(
          `Tagsági kártya ${
            statusMessage[userInfo.me.memberCardStatusHelper]
          }! Tranzakciók indítása nem elérhető!`,
          {
            variant: 'warning',
          }
        );
      }
    })();
  }, [accessToken]);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
