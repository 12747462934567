import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import BasicLayout from '../../layouts/basic_layout';
import styles from '../../pages/index.module.scss';
import MediaKit from '../../assets/MediaKit';

const useStyles = makeStyles({
  topContainer: {
    backgroundImage: `url(${MediaKit.body?.backgroundImageSrc})`,
  },
});

const Maintenance = () => {
  const classes = useStyles();

  return (
    <BasicLayout>
      <Grid
        className={styles.mainContainer}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Box
          className={`${styles.topContainer} ${classes.topContainer}`}
          style={{ height: '70vh' }}
          component="section"
        ></Box>
      </Grid>
    </BasicLayout>
  );
};

export default Maintenance;
