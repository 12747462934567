import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Container, Grid, makeStyles } from '@material-ui/core';
import NavigationBar from './Menubar/mainmenu';
import styles from './header.module.scss';
import MemberCardStatusWarningMessage from './MemberCardStatusWarningMessage/MemberCardStatusWarningMessage';
import { SystemStatusContext } from '../providers/SystemStatusProvider';
import MediaKit from '../assets/MediaKit';

const useStyles = makeStyles({
  mkdnyLogo: {
    backgroundImage: `url(${MediaKit.header?.logoImageSrc})`,
  },
});

const Header = () => {
  const { headerMessage } = useContext(SystemStatusContext);

  const classes = useStyles();

  return (
    <header className={styles.headerContainer}>
      <Container maxWidth="lg">
        <Grid
          container
          component="div"
          className={styles.headerWrapper}
          spacing={0}
          alignItems="center"
        >
          <Grid item component="div" md={3} className={styles.logoSpace}>
            <Link to={MediaKit.header?.logoLinkUrl}>
              <div className={`${styles.mkdnyLogo} ${classes.mkdnyLogo}`} />
            </Link>
          </Grid>
          <Grid item component="div" md={9} className={styles.menuSpace}>
            <NavigationBar />
          </Grid>
        </Grid>
      </Container>
      {headerMessage && (
        <MemberCardStatusWarningMessage message={headerMessage} />
      )}
    </header>
  );
};

export default Header;
