// CommonJS formátumot követ a fájl, hogy build időben is használható legyen.

/**
 * The app's current deployment code.
 */
module.exports.deploymentCode = process.env.GATSBY_DEPLOYMENT_CODE || 'kshk';

/**
 * List of controlled app features.
 */
module.exports.controlledFeatures = {
  homepagePath: process.env.GATSBY_HOMEPAGE_PATH ?? '/',
  itemList: process.env.GATSBY_ITEM_LIST_AVAILABLE === 'true',
  loan: process.env.GATSBY_LOAN_AVAILABLE === 'true',
  login: process.env.GATSBY_LOGIN_AVAILABLE === 'true',
  recordList: process.env.GATSBY_RECORD_LIST_AVAILABLE === 'true',
  submissionReport: process.env.GATSBY_SUBMISSION_REPORT_AVAILABLE === 'true',
};

const getPathsToIgnore = () => {
  const paths = [];
  if (!this.controlledFeatures.recordList) {
    paths.push(`/record_list/`);
    paths.push(`/elements/`);
    paths.push(`/multiprint/`);
  }

  if (!this.controlledFeatures.login) {
    paths.push(`/bejelentkezes/`);
    paths.push(`/forgotten-password/`);
    paths.push(`/profil/`);
    paths.push(`/password_reset/accept/`);
  }

  if (!this.controlledFeatures.submissionReport) {
    paths.push(`/kotelespeldanyok/`);
  }

  return paths;
};

/**
 * Paths that will be ignored when creating pages.
 */
module.exports.pathsToIgnore = getPathsToIgnore();

console.log('controlledFeatures', this.controlledFeatures);
console.log('pathsToIgnore', this.pathsToIgnore);
