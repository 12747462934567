// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bejelentkezes-js": () => import("./../../../src/pages/bejelentkezes.js" /* webpackChunkName: "component---src-pages-bejelentkezes-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-forgotten-password-js": () => import("./../../../src/pages/forgotten-password.js" /* webpackChunkName: "component---src-pages-forgotten-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kotelespeldany-szolgaltatasrol-js": () => import("./../../../src/pages/kotelespeldany-szolgaltatasrol.js" /* webpackChunkName: "component---src-pages-kotelespeldany-szolgaltatasrol-js" */),
  "component---src-pages-multiprint-js": () => import("./../../../src/pages/multiprint.js" /* webpackChunkName: "component---src-pages-multiprint-js" */),
  "component---src-pages-password-reset-accept-js": () => import("./../../../src/pages/password_reset/accept.js" /* webpackChunkName: "component---src-pages-password-reset-accept-js" */),
  "component---src-pages-profil-js": () => import("./../../../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-record-list-js": () => import("./../../../src/pages/record_list.js" /* webpackChunkName: "component---src-pages-record-list-js" */)
}

