import { makeVar } from '@apollo/client';

/**
 * @typedef UserInfo
 * @property {boolean} isMemberCardActive
 * @property {('Active'|'Inactive'|'Expired'|'Disabled')} status
 */
const defaultUserInfo = {
  isMemberCardActive: false,
  status: '',
};

export const userInfoVar = makeVar(defaultUserInfo);
