import { useQuery, useReactiveVar } from "@apollo/client"
import { accessTokenVar } from "../apollo/localState/accessTokenVar";
import { FETCH_MY_TRANSACTIONS, FETCH_MY_TRANSACTIONS_COUNT } from "../apollo/queries/searchRelatedQueries"

export const useFetchAllTransaction = () => {
  const { accessToken } = useReactiveVar(accessTokenVar);

  const { data: countData } = useQuery(FETCH_MY_TRANSACTIONS_COUNT, {
    skip: !accessToken
  });

  const { data, loading } = useQuery(FETCH_MY_TRANSACTIONS, {
    variables: {
      take: Math.max(countData?.me?.myActiveLoans_count, countData?.me?.myActiveRequests_count, countData?.me?.myActiveReservations_count),
      skip: 0,
    },
    skip: !countData || !accessToken,
  })

  return { data: {
    ...countData?.me,
    ...data?.me
  }, loading }
}