//Search

export const ON_SET_KEYWORD_FOR_BASIC_SEARCH = 'ON_SET_KEYWORD_FOR_BASIC_SEARCH'
export const ON_SET_KEYWORD_FROM_URL = 'ON_SET_KEYWORD_FROM_URL'
export const ON_EMPTY_SEARCH = 'ON_EMPTY_SEARCH'

export const ON_ADD_TYPE_FILTER = 'ON_ADD_TYPE_FILTER'
export const ON_SET_BUCKETS = 'ON_SET_BUCKETS'

export const ON_CHANGE_FILTER_VISUAL = 'ON_CHANGE_FILTER_VISUAL'

export const ON_SEARCHABLE_LOCATION_CHANGE  = 'ON_SEARCHABLE_LOCATION_CHANGE'

export const ON_FILTER_FOR_YEAR_TO_YEAR = 'ON_SEARCH_FOR_YEAR_TO_YEAR'
export const ON_FILTER_FOR_EXACT_YEAR = 'ON_SEARCH_FOR_EXACT_YEAR'
export const ON_FILTER_FOR_MATCH_YEAR = 'ON_FILTER_FOR_MATCH_YEAR'

export const ADD_LIBRARY_CODE_FILTER = 'ADD_LIBRARY_CODE_FILTER'
export const ADD_LIBRARY_ELSE_FILTER = 'ADD_LIBRARY_ELSE_FILTER'
export const SET_LIBRARY_CODE_BUCKET = 'SET_LIBRARY_CODE_BUCKET'

// Sorting
export const SET_ABC_SORT = 'SET_ABC_SORT'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const SET_OFFSET = 'SET_OFFSET'

//AUTH
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const REMOVE_ACCESS_TOKEN = 'REMOVE_ACCESS_TOKEN';


