export const setAbcSort = (state,action) => {
    const { title, value } = action
            
    switch(title) {
        case 'author':
            return {
                ...state,
                orderBy: {
                    key: "author.keyword",
                    order: value ? 'ASC' : 'DESC',
                    activeTitle: title
                }
            }
        case 'title':
            return {
                ...state,
                orderBy: {
                    key: "title.keyword",
                    order: value ? 'ASC' : 'DESC',
                    activeTitle: title
                }
            }
        case 'date':
            return {
                ...state,
                orderBy: {
                    key: 'dateOfAppearance',
                    order: value ? 'ASC' : 'DESC',
                    activeTitle: title
                }
            }
        case 'relevance': 
            return {
                ...state,
                orderBy: {
                    key: "_score",
                    order: value ? 'ASC' : 'DESC',
                    activeTitle: title 
                }
            }
        default: return state                    
    }
}