// CommonJS formátumot követ a fájl, hogy build időben is használható legyen.

const isxn = require('./isxn');
const kshk = require('./kshk');
const oszk = require('./oszk');
const wald = require('./wald');

const deploymentCode = process.env.GATSBY_DEPLOYMENT_CODE || 'kshk';

const deployments = {
  isxn: isxn,
  kshk: kshk,
  oszk: oszk,
  wald: wald,
};

/**
 * @type {MediaKit}
 */
const currentDeployment = deployments?.[deploymentCode] || kshk;

console.log('deploymentCode', deploymentCode);
console.log('currentDeployment', currentDeployment);

module.exports = currentDeployment;
